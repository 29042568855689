import ReactGA, { EventArgs } from 'react-ga';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || '';

export function init() {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  const isTest = !process.env.NODE_ENV || process.env.NODE_ENV === 'test';
  ReactGA.initialize(TRACKING_ID, { debug: isDev, testMode: isTest });
}

export function sendEvent(payload: EventArgs) {
  ReactGA.event(payload);
}

export function sendPageView(path: string) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

const exports = {
  init,
  sendEvent,
  sendPageView,
};

export default exports;

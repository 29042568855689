import axios from 'axios';
import { DateTime } from 'luxon';

import api, { AxiosResponse } from '@/api';
import {
  GetAuthResponse,
  GetUserResponse,
  GetFilterResponse,
  GetUserMetricsResponse,
  GetMetricsResponse,
  AuthDetails,
  UserToken,
  UserPermissions,
  MetricItem,
  UserSession,
  UserInfo,
  UserProfile,
} from './interface';

export async function getSessionFromAuthCode(code: string): Promise<AuthDetails> {
  const response: AxiosResponse<GetAuthResponse> = await api.get('/auth', {
    params: {
      code,
      is_local: process.env.REACT_APP_IS_LOCAL === 'true' ? true : undefined,
    },
  });

  return handleTokenResponse(response);
}

export async function getNewTokenFromRefreshToken(token?: UserToken): Promise<UserToken> {
  if (!token?.refreshToken) {
    throw new Error('A refresh token is required');
  }

  const refreshAPI = axios.create({
    baseURL: process.env.REACT_APP_DISTILR_API,
  });

  const response: AxiosResponse<GetAuthResponse> = await refreshAPI.get('/auth', {
    params: {
      refresh_token: token.refreshToken,
      is_local: process.env.REACT_APP_IS_LOCAL === 'true' ? true : undefined,
    },
  });

  return handleTokenResponse(response).token;
}

export async function getUserSession(): Promise<UserSession> {
  const response: AxiosResponse<GetUserMetricsResponse> = await api.get('/user/metrics');
  return response.data.session;
}

export async function saveUserSession(session: UserSession): Promise<UserSession> {
  await api.post('/user/metrics', { session });
  // TODO: should be returned from the API
  return session;
}

export async function getUserInfo(): Promise<UserInfo> {
  const response: AxiosResponse<GetUserResponse> = await api.get('/user');
  return response.data;
}

export async function getMetricList(): Promise<MetricItem[] | Error> {
  const response: AxiosResponse<GetMetricsResponse> = await api.get('/metrics');
  return response.data['body-json'].metrics_list;
}

export async function getUserMemberPermissions(): Promise<UserPermissions> {
  const response: AxiosResponse<GetFilterResponse> = await api.get('/filter');

  // TODO: FIX API
  if (response.data.errors?.length) {
    throw new Error(response.data.errors);
  }

  return response.data.body;
}

export function isUserTokenValid(token?: UserToken): boolean {
  if (!token) {
    return false;
  }

  const currentTime = DateTime.local();
  const expiresTime = DateTime.fromISO(token.initialized).plus({ seconds: token.expires });

  if (expiresTime.toMillis() - currentTime.toMillis() < 300000) {
    return false;
  }

  return true;
}

function handleTokenResponse(response: AxiosResponse<GetAuthResponse>): AuthDetails {
  return {
    token: {
      accessToken: response.data.access_token,
      expires: parseInt(response.data.expires_in, 10),
      initialized: DateTime.local().toISO(),
      refreshToken: response.data.refresh_token,
    },
    profiles: response.data.profiles.sort(sortProfiles),
    role: response.data.role,
  };
}

function sortProfiles(a: UserProfile, b: UserProfile) {
  const aVal = a.team_description.toLowerCase();
  const bVal = b.team_description.toLowerCase();
  return aVal.localeCompare(bVal);
}

import { BrowserRouter as Router } from 'react-router-dom';
import Provider from '@/components/Provider';
import { ToastContainer } from 'react-toastify';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/styles.css';

import Routes from '@/views/Routes';

function App() {
  return (
    <Provider>
      <Router>
        <Routes />
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </Provider>
  );
}

export default App;
